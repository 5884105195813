// Handle Modal component <dialog> closing and polyfill loading

import dialogPolyfill from 'dialog-polyfill'

function clickedInDialog(e, dialog) {
  const rect = dialog.getBoundingClientRect()
  return (
    rect.top <= e.clientY &&
    e.clientY <= rect.top + rect.height &&
    rect.left <= e.clientX &&
    e.clientX <= rect.left + rect.width
  )
}

function closeDialog(dialog) {
  if (dialog.open) {
    dialog.close()
  }
}

async function dialog(e) {
  const dialogs = e.target.querySelectorAll('dialog')
  const closeButtons = e.target.querySelectorAll('.js-close-modal')
  if (dialogs.length) {
    // Register dialog with polyfill
    dialogs.forEach((dialog) => dialogPolyfill.registerDialog(dialog))

    // Close open dialog when backdrop is clicked
    dialogs.forEach((dialog) => {
      dialog.addEventListener('click', (event) => {
        const targetTag = event.target.tagName.toLowerCase()

        // Ignore clicks on interactive elements inside the dialog
        if (targetTag === 'select' || targetTag === 'option') {
          return
        }

        if (!clickedInDialog(event, dialog)) {
          closeDialog(dialog)
        }
      })
    })

    // Close all open dialogs when a close button is clicked
    closeButtons.forEach((button) => {
      button.addEventListener('click', (event) => {
        event.preventDefault()
        dialogs.forEach((dialog) => closeDialog(dialog))
      })
    })
  }
}

document.addEventListener('starfish:render', dialog)
